<template>
  <el-dialog
    title="取消预定"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_2">
      注：当前已锁定考试名额，完成支付后即完成报名。当前操作会放弃锁定名额，确认取消预定吗？
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { unSeat } from "r/index/order.js";
export default {
  name: "CancelReserve",
  props: {
    reserveDialogFu: Boolean,
    examuuid: String,
  },
  data() {
    return {
      dialogVisible: this.reserveDialogFu,
    };
  },
  methods: {
   async confirm() {
      let { data: res } = await unSeat({ exam_uuid: this.examuuid });
      console.log(res);
      if(res.code !== 200) return this.$message.error(res.message);

      this.dialogVisible = false;
      this.$emit("closeReserveDialog", this.dialogVisible, true);
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeReserveDialog", this.dialogVisible, false);
    },
  },
  watch: {
    reserveDialogFu() {
      this.dialogVisible = this.reserveDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
</style>
