<template>
    <el-dialog
            title="微信支付"
            :visible.sync="dialogVisible"
            width="520px"
            top="0"
            :close-on-click-modal="false"
            @open="open"
    >
        <div v-show="isShowqr&&isUrl">
            <div class="info_2" v-if="exam_type==6 && timeQr>0">距离二维码过期还剩<span> {{timeQr}} </span>秒，过期后请刷新页面重新获取二维码。</div>
            <div class="info_2" v-if="exam_type==6 && timeQr<=0">二维码已过期，<strong @click="refreshQr"> 刷新 </strong>页面重新获取二维码。</div>
            <div class="img_qr">
                <div class="qrcode" ref="qrCodeUrl"></div>
                <div class="shadow" v-if="timeQr<=0">
                    <div>二维码已过期</div>
                </div>
            </div>
            <div class="info_2" style="color: #000">请使用微信扫描二维码完成支付</div>
            <div class="info_2">
                手机端完成支付后，请等待系统处理，在此之前请勿关闭窗口
            </div>
        </div>
        <div v-show="isShowqr && !isUrl">
            <div class="info_2">
                请等待系统处理，在此之前请勿关闭窗口
            </div>
        </div>
        <div v-show="!isShowqr">
            <div class="info_2" style="color: #000;font-size:18px">
                <i class="el-icon-warning"></i>{{ message }}
            </div>
        </div>

        <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {getWxConfig} from "r/index/order.js";
    import {SERVER_WS_URL} from "config/server";
    import QRCode from "qrcodejs2";

    export default {
        name: "ConfirmSignUp",
        props: {
            confirmSignUpDialogFu: Boolean,
            examuuid: String,
        },
        data() {
            return {
                dialogVisible: this.confirmSignUpDialogFu,
                message: "",
                isShowqr: true, // 展示支付二维码
                isUrl: false,
                token: "",
                websocket: null, //websocket连接
                lockReconnect: false, //是否真正建立连接
                timeout: 28 * 1000, //30秒一次心跳
                timeoutObj: null, //心跳心跳倒计时
                serverTimeoutObj: null, //心跳倒计时
                timeoutnum: null, //断开 重连倒计时
                exam_type: null,// 当前考试的type（4-TACA 6-TDA）
                timerQr: null,// exam_type==6 二维码过期倒计时
                timeQr: 60// exam_type==6 二维码过期时间
            };
        },
        created() {
            this.token = window.localStorage.getItem("index-token");
        },
        methods: {
            open() {
                this.timeQr = 60;
                this.exam_type = window.localStorage.getItem("exam_type");
                this.confirm();
            },
            // 刷新按钮，刷新二维码
            refreshQr(){
                this.removeQr();
                this.confirm();
            },
            async confirm() {
                let {data: res} = await getWxConfig({
                    exam_uuid: this.examuuid,
                    pay_type: 1,
                    is_refresh: this.exam_type == 6 ? 1 : 0
                });
                console.log(res);
                if (res.code !== 200) {
                    if (res.code == 400801) {
                        return this.cancelBtn();
                    }
                    this.message = res.message;
                    this.isShowqr = false;
                    return;
                }
                if (this.exam_type == 6) {
                    this.timeQr = 60;
                    clearInterval(this.timerQr);
                    this.timerQr = setInterval(() => {
                        if (this.timeQr <= 0) {
                            clearInterval(this.timerQr);
                        }
                        this.timeQr--;
                        console.log(this.timeQr)
                    }, 1000)
                }
                this.isShowqr = true;
                this.$emit("setOrderStatus", true);
                if (res.code_url) {
                    this.isUrl = true;
                    this.createQrCode(res.code_url);
                }
                //初始化 websocket 链接
                this.initWebSocket();
            },
            createQrCode(qrCode) {
                let qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: qrCode, // 需要转换为二维码的内容
                    width: 120,
                    height: 120,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
            // 删除二维码img标签
            removeQr(){
                if (this.$refs.qrCodeUrl) {
                    // 获取 父 标签下的所有子节点
                    let pObjs = this.$refs.qrCodeUrl.childNodes;
                    console.log(pObjs);
                    for (let i = pObjs.length - 1; i >= 0; i--) {
                        // 一定要倒序，正序是删不干净的，可自行尝试
                        this.$refs.qrCodeUrl.removeChild(pObjs[i]);
                    }
                }
            },
            // 确认,关闭弹框，修改父组件的值
            cancelBtn() {
                // 关闭时，删除二维码img标签
                this.removeQr();

                clearInterval(this.timerQr);
                this.closeWebSocket();
                this.dialogVisible = false;
                this.$emit("closeCFSUDialog", this.dialogVisible, false);
            },
            // 初始化 webSocket
            initWebSocket() {
                if (typeof WebSocket == "undefined") {
                    this.$message({
                        showClose: true,
                        message: "您的浏览器不支持WebSocket",
                        type: "error",
                    });
                } else if (!this.dialogVisible) {
                    return;
                } else {
                    if (!this.token || this.token == "") {
                        this.token = window.localStorage.getItem("index-token");
                    }
                    const wssURL =
                        SERVER_WS_URL + "/city?token=" + this.token + "&channel=pc";
                    this.websocket = new WebSocket(wssURL);

                    // console.log(this.websocket)
                    // 连接发生错误的回调方法
                    this.websocket.onerror = this.websocketOnerror;
                    // 连接成功建立的回调方法
                    this.websocket.onopen = this.websocketOnopen;
                    // 接收到消息的回调方法
                    this.websocket.onmessage = this.websocketOnmessage;
                    // 连接关闭的回调方法
                    this.websocket.onclose = this.websocketOnclose;
                    // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
                    this.websocket.onbeforeunload = this.websocketOnbeforeunload;
                    // this.websocketSend();
                    // setTimeout(() => {
                    //   this.websocketSend();
                    // }, 1000);
                }
            },
            reconnect() {
                //重新连接
                let that = this;
                if (!that.dialogVisible) {
                    return;
                }
                if (that.lockReconnect) {
                    return;
                }
                that.lockReconnect = true;
                //没连接上会一直重连，设置延迟避免请求过多
                that.timeoutnum && clearTimeout(that.timeoutnum);
                that.timeoutnum = setTimeout(function () {
                    //新连接
                    that.initWebSocket();
                    that.lockReconnect = false;
                }, 5000);
            },
            reset() {
                //重置心跳
                let that = this;
                //清除时间
                clearTimeout(that.timeoutObj);
                clearTimeout(that.serverTimeoutObj);
                //重启心跳
                that.start();
            },
            start() {
                //开启心跳
                let self = this;
                self.timeoutObj && clearTimeout(self.timeoutObj);
                self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
                self.timeoutObj = setTimeout(function () {
                    //这里发送一个心跳，后端收到后，返回一个心跳消息，
                    if (self.websocket.readyState == 1) {
                        //如果连接正常
                        self.websocket.send("heartCheck");
                    } else {
                        //否则重连
                        self.reconnect();
                    }
                    self.serverTimeoutObj = setTimeout(function () {
                        //超时关闭
                        self.websocket.close();
                    }, self.timeout);
                }, self.timeout);
            },
            // 连接发生错误的回调方法
            websocketOnerror() {
                this.$message.error("WebSocket连接发生错误");
                //重连
                this.reconnect();
            },
            // 连接成功建立的回调方法
            websocketOnopen() {
                this.websocketSend();
                console.log("连接成功建立的回调方法");
                //开启心跳
                this.start();
            },
            websocketSend() {
                //数据发送
                let sendData = {
                    order_no: window.localStorage.getItem("order_no"),
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                    token: this.token,
                };
                this.websocket.send(JSON.stringify(sendData));
            },
            // 接收到消息的回调方法
            websocketOnmessage(event) {
                console.log(event, "event");
                if (event.data !== "Opened") {
                    //const data = JSON.parse(event.data);
                    console.log("接收到消息的回调方法", event.data);
                    if (event.data == 1) {
                        this.lockReconnect = false;

                        this.closeWebSocket();
                        this.dialogVisible = false;
                        this.$emit("closeCFSUDialog", this.dialogVisible, true);

                        this.$router.replace({name: "applysuccess"});
                    } else {
                        //收到服务器信息，心跳重置
                        this.reset();
                    }
                }
            },
            // 连接关闭的回调方法
            websocketOnclose() {
                console.log("连接关闭的回调方法");
                //重连
                this.reconnect();
            },
            // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常
            websocketOnbeforeunload() {
                this.closeWebSocket();
                // console.log('监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常');
            },
            // 关闭WebSocket连接
            closeWebSocket() {
                this.websocket && this.websocket.close();
            },
        },
        // 页面注销时候调用 避免连接错误
        // destroyed() {
        //   this.closeWebSocket();
        // },
        watch: {
            confirmSignUpDialogFu() {
                this.dialogVisible = this.confirmSignUpDialogFu;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/dialog";

    .info_2 {
        text-align: center;
        margin: 10px 0;
        line-height: 30px !important;

        .el-icon-warning {
            font-size: 30px;
            vertical-align: bottom;
            margin-right: 10px;
            color: #ee7602;
        }

        span {
            color: #ee7602;
            font-weight: bold;
            font-family: PingFangSC-Regular;
        }

        strong {
            color: #ee7602;
            cursor: pointer;
        }
    }

    .img_qr {
        position: relative;
        width: 140px;
        height: 140px;
        padding: 10px;
        border: 1px solid #000;
        margin: 0 auto;

        img {
            width: 120px;
            height: 120px;
        }
        .shadow{
            width: 100%;
            height: 100%;
            color: #ffffff;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,0.2);
            >div{
                width: 100px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                background-color: rgba(0,0,0,0.6);
            }
        }
    }

    ::v-deep .el-dialog__body {
        padding: 0 !important;
    }

    ::v-deep .el-icon-close:before {
        content: "";
    }

    ::v-deep .el-dialog__footer {
        padding: 30px 40px !important;
        text-align: center;

        .el-button {
            margin: 0 !important;
        }
    }
</style>
