<template>
    <el-dialog
            title="相关说明"
            :visible.sync="dialogVisible"
            width="550px"
            top="0"
            :before-close="cancelBtn"
            :close-on-click-modal="false"
            @open="openHandle"
    >
        <div>
            <div class="info_2">
                1、本次考试费用包含基础报告(报告中包含成绩及知识点正确率等数据)，不包含认证成绩单。
            </div>
            <br/>
            <div class="info_2">
                2、报名成功后，如因特殊原因不能参加本次考试，可在截止报名时间{{enroll_end_time}}前，“个人信息-我的考试”页面，发起取消报名申请。系统审核后，费用将在3-5个工作日原路返回至支付账户。报名截止后，不受理退费。
            </div>
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button class="confirm agree" @click="confirm()">我已阅读并同意</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {formatDate2} from "../../../store/time";
    export default {
        name: "AgreeBox",
        props: {
            agreeBoxDialogFu: Boolean,
        },
        data() {
            return {
                dialogVisible: this.agreeBoxDialogFu,
                enroll_end_time: '',
            };
        },
        methods: {
            openHandle(){
                let enroll_end_time = window.localStorage.getItem("enroll_end_time");
                this.enroll_end_time = formatDate2(enroll_end_time*1000);
            },
            confirm() {
                this.dialogVisible = false;
                this.$emit("closeABDialog", this.dialogVisible, true);
            },
            cancelBtn(){
                this.dialogVisible = false;
                this.$emit("closeABDialog", this.dialogVisible, false);
            }
        },
        watch: {
            agreeBoxDialogFu() {
                this.dialogVisible = this.agreeBoxDialogFu;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/dialog";

    .info_2 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
    }
    ::v-deep .el-dialog__footer{
        padding: 30px 40px !important;
        .agree{
            width: 100%;
            height: 48px !important;
        }
    }
</style>
